import "./tailwind.css"
import "normalize.css"
import {
  DataDogProvider,
  FrontendServices,
  IntercomProvider,
  KaizenAdapter,
  MetaProvider,
  VersionSwitcher,
} from "@cultureamp/next-services"
import { DynamicIntlProvider } from "@cultureamp/i18n-react-intl"
import type { AppProps } from "next/app"

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <FrontendServices
      services={[
        MetaProvider,
        KaizenAdapter,
        IntercomProvider,
        DataDogProvider,
        VersionSwitcher,
      ]}
      options={{
        defaultTitle: "Culture Amp",
        datadogConfiguration: {
          service: "error-pages",
          applicationId: "686ae73e-17f0-4fd5-b203-5400933d3a52",
          clientToken: "pub3ee56ef3a317f93699afb16813037d55",
        },
      }}
    >
      <DynamicIntlProvider>
        <Component {...pageProps} />
      </DynamicIntlProvider>
    </FrontendServices>
  )
}

export default MyApp
